import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import styled from "@emotion/styled"
import { DialogTitle } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

export interface Props {
  show: boolean,
  title?: string | null
  message: string | null
  hideNegativeButton?: boolean | null
  positiveClick?: (() => void) | null
  negativeButton?: string | null
  positiveButton?: string | null
  closed?: (() => void) | null
}

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.93);
  }
`


export default function AlertDialog(props: Props) {
  const [open, setOpen] = React.useState(props.show)

  const handleClose = () => {
    setOpen(false)
    console.log("closing")
    if (props.closed) {
      props.closed()
    }
  }

  const dismiss = () => {
    handleClose()
  }

  const handlePositiveClick = () => {
    dismiss()

    if (props.positiveClick) {
      props.positiveClick()
    }
  }

  return (
    <div>
      <StyledDialog
        open={open}
        PaperProps={{ variant: "outlined" }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.title && <DialogTitle disableTypography={true}>
          <Typography variant={"h3"}>{props.title.toUpperCase()}</Typography>
        </DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!(props.hideNegativeButton ?? false) &&
          <Button onClick={dismiss} color="primary">
            {props.negativeButton ?? `CANCEL`}
          </Button>}
          <Button onClick={handlePositiveClick} color="primary">
            {props.positiveButton ?? `OK`}
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}